import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import json from './homepage.json';

const Homepage = () => {
  useEffect(() => {
    const logo = document.getElementsByClassName('homepage')[0];
    lottie.loadAnimation({
      container: logo,
      animationData: json,
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
  }, []);

  return (
    <div style={{ flex: 1, justifyContent: 'flex-start' }}>
      <h1 className='secondary'>
        Welcome to the <br />
        world of Amplify
      </h1>
      <div className='homepage'></div>
    </div>
  );
};

export default Homepage;
