import React from 'react';
import Segment from './Segment';
import AchievingAGoal from './AchievingAGoal';
import IndulgeMyPassions from './IndulgeMyPassions';
import TakeMeAway from './TakeMeAway';
import FeelGood from './FeelGood';
import KeepMeCompany from './KeepMeCompany';
import QuickNewsFix from './QuickNewsFix';

const SegmentContainer = () => {
  const segments = [
    {
      segment: AchievingAGoal,
      title: 'Achieving a Goal',
      subtitle: 'Maximise learning in a specific personal growth area',
      quote: {
        body:
          'I want to take advantage of every free moment and be productive. Mental well-being podcasts give me practical skills to develop my mind and grow as a person.',
        author: 'Barcelona, Young Family, AAG',
      },
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      button: {
        label: 'Find out more',
        href:
          '//sparkler-amplify.s3.eu-west-2.amazonaws.com/pdfs/Achieving_a_goal.pdf',
      },
      color: 'green',
      audio:
        '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+06+(AAG).mp3',
    },
    {
      segment: IndulgeMyPassions,
      title: 'Indulge my passions',
      subtitle: 'Be entertained while learning more about personal interests',
      quote: {
        body:
          'Podcasts that speak about my interests in an intelligent and engaging way are fun to listen to, plus give me new ways of thinking about them',
        author: 'XX',
      },
      content: '',
      button: {
        label: 'Find out more',
        href:
          '//sparkler-amplify.s3.eu-west-2.amazonaws.com/pdfs/Indulge_my_passions.pdf',
      },
      color: 'red',
      audio:
        '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+07+(IMP).mp3',
    },
    {
      segment: TakeMeAway,
      title: 'Take me away',
      subtitle:
        'An immersive experience for escapism that stimulates the imagination',
      quote: {
        body:
          'I want to just put everything from the day aside and explore a different kind of life to broaden my horizons',
        author: 'XX',
      },
      content: '',
      button: {
        label: 'Find out more',
        href:
          '//sparkler-amplify.s3.eu-west-2.amazonaws.com/pdfs/Take_me_away.pdf',
      },
      color: 'orange',
      audio:
        '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+08+(TMA).mp3',
    },
    {
      segment: FeelGood,
      title: 'Feel good',
      subtitle: 'Light-hearted and easy listening to relax and unwind',
      quote: {
        body:
          'I like listening to things I don’t need to concentrate on to understand, but can also be a good distraction from overthinking',
        author: 'XX',
      },
      content:
        '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+09+(FG).mp3',
      button: {
        label: 'Find out more',
        href:
          '//sparkler-amplify.s3.eu-west-2.amazonaws.com/pdfs/Feel_good.pdf',
      },
      color: 'blue',
      audio:
        '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+09+(FG).mp3',
    },
    {
      segment: KeepMeCompany,
      title: 'Keep me company',
      subtitle:
        'Content to have on in the background while doing something else',
      quote: {
        body:
          'It’s routine for me to have the radio on when cooking or pottering about. I mostly zone in and out of it and don’t mind missing the details',
        author: 'XX',
      },
      content: '',
      button: {
        label: 'Find out more',
        href:
          '//sparkler-amplify.s3.eu-west-2.amazonaws.com/pdfs/Keep_me_company.pdf',
      },
      color: 'pink',
      audio:
        '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+10+(KMC).mp3',
    },
    {
      segment: QuickNewsFix,
      title: 'Quick news fix',
      subtitle: 'Headlines on the latest news bulletins',
      quote: {
        body:
          'Listening to news updates in the morning is a very convenient way to find out what’s going on in the world',
        author: 'XX',
      },
      content: '',
      button: {
        label: 'Find out more',
        href:
          '//sparkler-amplify.s3.eu-west-2.amazonaws.com/pdfs/Quick_news_fix.pdf',
      },
      color: 'yellow',
      audio:
        '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+11+(QNF).mp3',
    },
  ];

  return segments.map((segment, k) => <Segment {...segment} key={k} />);
};

export default SegmentContainer;
