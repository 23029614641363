import React from 'react';
import classnames from 'classnames';
import ChevronIcon from '../../icons/Chevron.svg';

const Chevron = ({ open }) => {
  const chevnronClasses = classnames({
    chevron: true,
    open: open,
  });

  return (
    <div className={chevnronClasses}>
      <img src={ChevronIcon} alt='Chevron' />
    </div>
  );
};

export default Chevron;
