import React, { useContext, useState, useEffect } from 'react';
import { AudioContext } from '../../context/AudioContext';
import Block from './Block';
import AchievingAGoalAGoalIcon from '../../icons/achieving-a-goal.svg';
import IndulgeMyPassionsIcon from '../../icons/indulge-my-passions.svg';
import TakeMeAwayIcon from '../../icons/take-me-away.svg';
import FeelGoodIcon from '../../icons/feel-good.svg';
import KeepMeCompanyIcon from '../../icons/keep-me-company.svg';
import QuickNewsFixIcon from '../../icons/quick-news-fix.svg';

const blocks = [
  {
    title: 'Achieving a goal',
    hash: 'achieving-a-goal',
    color: 'green',
    icon: AchievingAGoalAGoalIcon,
  },
  {
    title: 'Indulge my passions',
    hash: 'indulge-my-passions',
    color: 'red',
    icon: IndulgeMyPassionsIcon,
  },
  {
    title: 'Take me away',
    hash: 'take-me-away',
    color: 'orange',
    icon: TakeMeAwayIcon,
  },
  {
    title: 'Feel good',
    hash: 'feel-good',
    color: 'blue',
    icon: FeelGoodIcon,
  },
  {
    title: 'Keep me company',
    hash: 'keep-me-company',
    color: 'pink',
    icon: KeepMeCompanyIcon,
  },
  {
    title: 'Quick news fix',
    hash: 'quick-news-fix',
    color: 'yellow',
    icon: QuickNewsFixIcon,
  },
];

const BlockContainer = () => {
  const { setAudioSource } = useContext(AudioContext);
  const [isVisible, setVisible] = useState(false);
  const [played, setPlayed] = useState(false);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        const demandSpace = document.getElementById('demand-spaces');
        const currentPosition = window.pageYOffset;
        const elementOffset = demandSpace.offsetTop;
        const calculation = currentPosition >= elementOffset;

        if (calculation) {
          setInView(true);
          setVisible(true);
        }
      },
      false,
    );
  }, []);

  if (inView && !played) {
    setPlayed(true);
    setAudioSource(
      '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+05.mp3',
    );
  }

  return (
    <section id='demand-spaces'>
      <div className='outer-container'>
        <div className='sticky'>
          <div className='block__container'>
            {blocks.map((block, k) => (
              <Block {...block} key={k} on={isVisible} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlockContainer;
