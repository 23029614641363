import React, { useContext, useEffect, useState } from 'react';
import { AudioContext } from '../../context/AudioContext';
import classnames from 'classnames';
import HelpIcon from '../../icons/Help.svg';
import ExploreIcon from '../../icons/Explore.svg';
import AnswerIcon from '../../icons/Answer.svg';
import DirectIcon from '../../icons/Direct.svg';
import Chevron from '../../icons/Chevron.svg';

const HelpContent = ({ title, icon, alt, content, audio }) => {
  const { setAudioSource } = useContext(AudioContext);
  const [open, toggle] = useState(null);
  const [played, setPlayed] = useState(false);

  const chevnronClasses = classnames({
    chevron: true,
    open: open,
  });

  const classes = classnames({
    content: true,
    open: open,
    cssanimation: true,
    fadeInTop: open,
    fadeOutTop: open !== null && !open,
  });

  if (open && !played) {
    setAudioSource(audio);
    setPlayed(true);
  }

  return (
    <div onClick={() => (open ? toggle(false) : toggle(true))}>
      <img src={icon} alt={alt} />
      <div className='help'>{title}</div>
      <div className={chevnronClasses}>
        <img src={Chevron} alt='Chevron' />
      </div>
      <p className={classes}>{content}</p>
    </div>
  );
};

const helpContent = [
  {
    title: 'Help',
    icon: HelpIcon,
    alt: 'Help',
    content:
      'Use it to help harness prioritize and implement the right strategies to push the business forward',
    audio:
      '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+04_02.mp3',
  },
  {
    title: 'Explore',
    icon: ExploreIcon,
    alt: 'Explore',
    content:
      'Use it to explore the most relevant content opportunities to develop',
    audio:
      '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+04_03.mp3',
  },
  {
    title: 'Answer',
    icon: AnswerIcon,
    alt: 'Answer',
    content: 'Use it to answer specific, tactical questions',
    audio:
      '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+04_04.mp3',
  },
  {
    title: 'Direct',
    icon: DirectIcon,
    alt: 'Direct',
    content: 'Use it to direct future opportunities for development',
    audio:
      '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+04_05.mp3',
  },
];

const Help = () => {
  const { setAudioSource } = useContext(AudioContext);
  const [played, setPlayed] = useState(false);
  const [inView, setInView] = useState(false);

  window.addEventListener('scroll', () => {
    const demandSpace = document.getElementById('help');
    const currentPosition = window.pageYOffset;
    const elementOffset = demandSpace.offsetTop;
    const calculation = currentPosition >= elementOffset;

    if (calculation) {
      setInView(true);
    }
  });

  if (inView && !played) {
    setPlayed(true);
    setAudioSource(
      '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+04_01.mp3',
    );
  }

  return (
    <section
      id='help'
      className='section primary hero'
      style={{ backgroundColor: '--color-placeholder' }}>
      <div className='container'>
        <div className='sticky'>
          <h3 className='secondary'>
            All of this means that you can use Amplify to:
          </h3>
          <div className='help__container'>
            {helpContent.map((props, k) => (
              <HelpContent {...props} key={k} />
            ))}
          </div>
          <h3 className='secondary'>
            So now that we know what we can do with Amplify, let’s further explore what
            makes it unique.
          </h3>
          <h3 className='secondary'>Our six Demand Spaces</h3>
        </div>
      </div>
    </section>
  );
};

export default Help;
