import React from 'react';

const Section = ({ id, title, body = '', className = 'primary', children }) => {
  return (
    <section
      id={id}
      className={`section ${className} hero`}
      style={{ backgroundColor: '--color-placeholder' }}>
      <div className='container outer-container'>
        <div className='container sticky'>
          <h1 className='secondary'>{title}</h1>
          {body && <h3 className='secondary'>{body}</h3>}
          {children}
        </div>
      </div>
    </section>
  );
};

export default Section;
