import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Family from '../../../icons/family.svg';
import Plant1 from '../../../icons/plant-1.svg';
import Plant2 from '../../../icons/plant-2.svg';
import Clock from '../../../icons/clock.svg';
import Cat from '../../../icons/cat.svg';
import Frame1 from '../../../icons/frame-1.svg';
import Frame2 from '../../../icons/frame-2.svg';
import Frame3 from '../../../icons/frame-3.svg';
import Frame4 from '../../../icons/frame-4.svg';
import Man from '../../../icons/man.svg';
import Table from '../../../icons/side-table.svg';
import Window from '../../../icons/window.svg';
import Woman from '../../../icons/woman.svg';

const V1 = ({ title, subtitle, quote, content, button, color }) => {
  const [offset, setOffset] = useState(0);
  const [windowOpacity, setWindowOpacity] = useState(0);
  const [mamalOpacity, setMamalsOpacity] = useState(0);
  const [furnitureOpacity, setFurnitureOpacity] = useState(0);
  const [catClassName, setCatClassNames] = useState({
    cat: true,
    cssanimation: true,
    fadeInBottom: false,
    fadeOutBottom: true,
  });
  const [womanClassName, setWomanClassNames] = useState({
    woman: true,
    cssanimation: true,
    fadeInLeft: false,
    fadeOutLeft: true,
  });
  const [manClassName, setManClassNames] = useState({
    man: true,
    cssanimation: true,
    fadeInRight: false,
    fadeOutRight: true,
  });
  const [quoteClassNames, setQuoteClassName] = useState({
    grid: true,
    container: true,
    cssanimation: true,
    fadeOutBottom: true,
  });

  useEffect(() => {
    let o = offset;

    window.addEventListener(
      'scroll',
      () => {
        const segment = document.getElementById('achieving-a-goal');
        const currentPosition = window.pageYOffset;
        const elementOffset = segment.offsetTop;
        const calculation = currentPosition >= elementOffset;
        const MAX_HEIGHT = segment.offsetHeight - window.innerHeight;

        if (calculation) {
          const calc = currentPosition - elementOffset;
          const currentPosPerc = calc / (MAX_HEIGHT / 100);

          if (currentPosPerc > 10) {
            setWindowOpacity(1);
          } else {
            setWindowOpacity(0);
          }

          if (currentPosPerc > 30) {
            setFurnitureOpacity(1);
          } else {
            setFurnitureOpacity(0);
          }

          if (currentPosPerc > 60) {
            setMamalsOpacity(1);
            setCatClassNames({
              cat: true,
              cssanimation: true,
              fadeInBottom: true,
              fadeOutBottom: false,
            });
            setWomanClassNames({
              woman: true,
              cssanimation: true,
              fadeInLeft: true,
              fadeOutLeft: false,
            });
            setManClassNames({
              man: true,
              cssanimation: true,
              fadeInRight: true,
              fadeOutRight: false,
            });
          } else {
            setMamalsOpacity(0);
            setCatClassNames({
              cat: true,
              cssanimation: true,
              fadeInBottom: false,
              fadeOutBottom: true,
            });
            setWomanClassNames({
              woman: true,
              cssanimation: true,
              fadeInLeft: false,
              fadeOutLeft: true,
            });
            setManClassNames({
              man: true,
              cssanimation: true,
              fadeInRight: false,
              fadeOutRight: true,
            });
          }

          if (currentPosPerc > 75) {
            setQuoteClassName({
              container: true,
              grid: true,
              cssanimation: true,
              fadeInBottom: true,
              fadeOutBottom: false,
            });
          } else {
            setQuoteClassName({
              container: true,
              grid: true,
              cssanimation: true,
              fadeInBottom: false,
              fadeOutBottom: true,
            });
          }

          setOffset(o);
        }
      },
      false,
    );
  }, []);

  const windows = { opacity: windowOpacity };
  const mamals = { opacity: mamalOpacity };
  const furniture = { opacity: furnitureOpacity };

  return (
    <section id='achieving-a-goal' className={`v1 section ${color}`}>
      <div className='sticky'>
        <div className='section-container'>
          <div className='container grid'>
            <h1 className='col-7'>{title}</h1>
          </div>
          <div className='container grid'>
            <p className='col-6'>{content}</p>
          </div>
          <div className={classnames(quoteClassNames)}>
            <div className='quote__container col-4'>
              <img src={Family} alt='Couple' className='icon' />
              <p className='quote'>
                I want to take advantage of every free moment and be productive.
                Mental well-being podcasts give me practical skills to develop
                my mind and grow as a person.
              </p>
              <p className='author'>Barcelona, Young Family, AAG</p>
            </div>
          </div>

          <div className='left-side'>
            <div>
              <img
                className='plant'
                src={Plant2}
                alt='Plant'
                style={furniture}
              />
              <img
                className={classnames(womanClassName)}
                src={Woman}
                alt='Woman'
                style={mamals}
              />
              <img className={classnames(catClassName)} src={Cat} alt='Cat' />
              <img className='frame' src={Frame1} alt='Frame' style={windows} />
              <img
                className='frame2'
                src={Frame2}
                alt='Frame'
                style={windows}
              />
              <img
                className='frame3'
                src={Frame3}
                alt='Frame'
                style={windows}
              />
              <img
                className='frame4'
                src={Frame4}
                alt='Frame'
                style={windows}
              />
            </div>
          </div>
          <div className='right-side'>
            <div>
              <img
                className='table'
                src={Table}
                alt='Table'
                style={furniture}
              />
              <img className={classnames(manClassName)} src={Man} alt='Man' />
              <img
                className='window'
                src={Window}
                alt='Window'
                style={windows}
              />
              <img
                className='plant'
                src={Plant1}
                alt='Plant'
                style={furniture}
              />
              <img
                className='clock'
                src={Clock}
                alt='Clock'
                style={furniture}
              />
            </div>
          </div>
        </div>

        <div
          className={`primary`}
          style={{
            position: 'relative',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
            top: '177px',
            padding: '100px 0',
          }}>
          <div className='container grid'>
            <div style={{ color: 'white' }} className='col-3'>
              <div
                style={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '48px',
                  backgroundColor: 'white',
                  margin: '0 auto',
                }}></div>
              <p style={{ color: 'white' }}>
                <strong>Keep it brief</strong>
              </p>
              <p style={{ color: 'white' }}>
                Create content with 20-30 minute long chunks for maximum
                absorption
              </p>
            </div>
            <div style={{ color: 'white' }} className='col-3'>
              <div
                style={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '48px',
                  backgroundColor: 'white',
                  margin: '0 auto',
                }}></div>
              <p style={{ color: 'white' }}>
                <strong>Keep it brief</strong>
              </p>
              <p style={{ color: 'white' }}>
                Label content with action and output oriented titles e.g. How
                to…, 5 steps to…, 10 hacks for…
              </p>
            </div>
            <div style={{ color: 'white' }} className='col-3'>
              <div
                style={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '48px',
                  backgroundColor: 'white',
                  margin: '0 auto',
                }}></div>
              <p style={{ color: 'white' }}>
                <strong>Keep it brief</strong>
              </p>
              <p style={{ color: 'white' }}>
                Commission content in three areas: formal learning, career /
                business advancement skills and directional personal development
              </p>
            </div>
            <div style={{ color: 'white' }} className='col-3'>
              <div
                style={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '48px',
                  backgroundColor: 'white',
                  margin: '0 auto',
                }}></div>
              <p style={{ color: 'white' }}>
                <strong>Keep it brief</strong>
              </p>
              <p style={{ color: 'white' }}>
                Find individuals with credentials in the field to host content
                with informative, inclusive and motivational tones
              </p>
            </div>
          </div>

          <div className='cta cta--square cta--primary'>
            <h4>Find out more</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default V1;
