import React, { useContext, useEffect, useState } from 'react';
import { AudioContext } from '../../context/AudioContext';
import classnames from 'classnames';
import Button from '../../components/Button';
import Section from '../../components/Section';
import Chevron from '../../components/Chevron';
import { raw as clipData } from '../../content/clips.json';
const filters = [
  {
    title: 'Filter by Demand Space',
    options: [
      {
        name: 'Achieving a goal',
        value: 'AAG',
      },
      {
        name: 'Indulge my passions',
        value: 'IMP',
      },
      {
        name: 'Take me away',
        value: 'TMA',
      },
      {
        name: 'Feel good',
        value: 'FG',
      },
      // {
      //   name: 'Keep me company',
      //   value: 'KMC',
      // },
      // {
      //   name: 'Quick news fix',
      //   value: 'QNF',
      // },
      {
        name: 'General',
        value: 'GEN',
      },
    ],
  },
  {
    title: 'Filter by Country',
    spacing: 'three-per-row',
    options: [
      {
        name: 'Australia',
        value: 'AUS',
      },
      {
        name: 'Canada',
        value: 'CAN',
      },
      {
        name: 'France',
        value: 'FR',
      },
      {
        name: 'Germany',
        value: 'DE',
      },
      {
        name: 'Global',
        value: 'GBL',
      },
      {
        name: 'India',
        value: 'IN',
      },
      {
        name: 'Italy',
        value: 'IT',
      },
      {
        name: 'Japan',
        value: 'JP',
      },
      {
        name: 'Spain',
        value: 'ES',
      },
      {
        name: 'UK',
        value: 'UK',
      },
      {
        name: 'USA',
        value: 'US',
      },
    ],
  },
];

const FilterOption = ({
  title,
  options,
  spacing = '',
  selectedFilters = [],
  onFilter = () => {},
}) => {
  const [open, toggle] = useState(true);

  const toggleFilter = () => {
    if (open) {
      toggle(false);
    } else {
      toggle(true);
    }
  };

  const classes = classnames({
    open: open,
    cssanimation: true,
    fadeInTop: open,
    fadeOutTop: open !== null && !open,
  });

  return (
    <div className='clip-filter'>
      <div onClick={toggleFilter}>
        <h4 className='secondary'>{title}</h4>
        <Chevron open={open} />
      </div>
      <form>
        <ul className={classes}>
          {options.map((option) => (
            <li className={spacing}>
              <input
                type='checkbox'
                name={`${option.value}`}
                id={`${option.value}`}
                value={`${option.value}`}
                onChange={(e) => {
                  const index = selectedFilters.indexOf(e.target.value);
                  if (e.target.checked === false && index > -1) {
                    const localFilters = [...selectedFilters];
                    localFilters.splice(index, 1);
                    onFilter(localFilters);
                    console.log('remove', e.target.value, localFilters);
                  } else if (e.target.checked === true) {
                    const localFilters = [...selectedFilters];
                    localFilters.push(e.target.value);
                    onFilter(localFilters);
                    console.log(localFilters);
                  }
                }}
              />
              <label className='secondary' htmlFor={`${option.value}`}>
                {option.name}
              </label>
            </li>
          ))}
        </ul>
      </form>
    </div>
  );
};

const FIELD_MAP = {
  GEN: {
    label: 'General',
    className: 'achieving-a-goal',
  },
  AAG: {
    label: 'Achieving a goal',
    className: 'achieving-a-goal',
  },
  FGK: {
    label: 'Feel good',
    className: 'feel-good',
  },
  FG: {
    label: 'Feel good',
    className: 'feel-good',
  },
  IMP: {
    label: 'Indulge my passions',
    className: 'indulge-my-passions',
  },
  TMA: {
    label: 'Take me away',
    className: 'take-me-away',
  },
  QNF: {
    label: 'Quick news fix',
    className: 'quick-news-fix',
  },
  KMC: {
    label: 'Keep me company',
    className: 'keep-me-company',
  },
};

const COUNTRY_MAP = {
  AUS: 'Australia',
  CAN: 'Canada',
  FR: 'France',
  DE: 'Germany',
  GBL: 'Global',
  IN: 'India',
  IT: 'Italy',
  JP: 'Japan',
  ES: 'Spain',
  UK: 'United Kingdom',
  US: 'United States of America',
};

const Clip = ({ name, offset, onSetClip }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, offset);
  }, [opacity, offset]);

  const parts = name.split('_');
  const country = COUNTRY_MAP[parts[1]];
  const person = parts[2].charAt(0) + parts[2].slice(1).toLowerCase();
  const title = (
    parts[3].charAt(0).toUpperCase() + parts[3].slice(1).toLowerCase()
  ).replace(/\+/g, ' ');

  return (
    <div
      key={name}
      className={`clip ${FIELD_MAP[parts[0]].className}`}
      style={{ opacity }}
      data-field={parts[0]}
      data-country={parts[1]}
      data-person={person}
      onClick={() => {
        onSetClip(name);

        // Stop the main audio playing when the "homepage":  is played
        const audio = document.getElementsByTagName('audio')[0];
        audio.pause();

        const video = document.getElementById('video');
        video.load();
        video.play();

        const clipsContainer = document.getElementById('clips');
        clipsContainer.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }}>
      <h4>{FIELD_MAP[parts[0]].label}</h4>
      <h5>{country}</h5>
      <p>{title}</p>
    </div>
  );
};

const Video = ({ name }) => {
  const IMAGE_PREFIX =
    'https://sparkler-amplify.s3.eu-west-2.amazonaws.com/clips/images';

  const VIDEO_PREFIX =
    'https://sparkler-amplify.s3.eu-west-2.amazonaws.com/clips/videos';

  return (
    <video
      id={`video`}
      playsinline
      controls
      controlsList='nodownload'
      data-poster={`${IMAGE_PREFIX}/${name}.png`}>
      <source src={`${VIDEO_PREFIX}/${name}.mp4`} type='video/mp4' />
    </video>
  );
};

const actualClips = [...clipData];

const filterActualClips = (clips, filters, setPagination = () => {}) => {
  setPagination(6);

  if (filters.length === 0) {
    return clips;
  }

  const HAS_COUNTRY_FILTERS = Boolean(
    filters.find((filter) => Object.keys(COUNTRY_MAP).indexOf(filter) > -1),
  );

  const HAS_FIELD_FILTERS = Boolean(
    filters.find((filter) => Object.keys(FIELD_MAP).indexOf(filter) > -1),
  );

  const filteredClips = clips.filter((clip) => {
    // AAG_AUS_CAMERON_Harvard+Business+Review
    const parts = clip.split('_');
    const FIELD = parts[0];
    const COUNTRY = parts[1];

    const FIELD_FOUND = filters.find((filter) => filter === FIELD);
    const COUNTRY_FOUND = filters.find((filter) => filter === COUNTRY);

    if (
      HAS_COUNTRY_FILTERS === true &&
      HAS_FIELD_FILTERS === false &&
      COUNTRY_FOUND
    ) {
      return true;
    }

    if (
      HAS_FIELD_FILTERS === true &&
      HAS_COUNTRY_FILTERS === false &&
      FIELD_FOUND
    ) {
      return true;
    }

    return FIELD_FOUND && COUNTRY_FOUND;
  });

  return filteredClips;
};

const Container = () => {
  const { setAudioSource } = useContext(AudioContext);
  const [played, setPlayed] = useState(false);
  const [inView, setInView] = useState(false);
  const [selectedFilters, setFilters] = useState([]);
  const [pagination, setPagination] = useState(6);
  const [clips, setClips] = useState(
    filterActualClips(actualClips, selectedFilters).slice(0, pagination),
  );
  const [currentClip, setCurrentClip] = useState(clips[0]);

  let offset = 0;

  window.addEventListener('scroll', () => {
    const demandSpace = document.getElementById('clips');
    const currentPosition = window.pageYOffset;
    const elementOffset = demandSpace.offsetTop;
    const calculation = currentPosition >= elementOffset;

    if (calculation) {
      setInView(true);
    }
  });

  if (inView && !played) {
    setPlayed(true);
    setAudioSource(
      '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+12.mp3',
    );
  }

  useEffect(() => {
    const video = document.getElementById('video');
    video.onplay = () => {
      const audio = document.getElementsByTagName('audio')[0];
      audio.pause();
    };
  }, []);

  return (
    <Section
      id='clips'
      title='Clips'
      body='Below we have a collection of insightful and inspiring stories told by real listeners across the globe. These short video clips provide a window into listeners’ lives, helping us understand their motivations and preferences more clearly.'>
      <div className='clip-hero'>
        <Video name={currentClip} />
      </div>
      <p className='secondary' style={{ margin: '20px', marginTop: '60px' }}>
        Select a demand space and/or a marketplace to hear from listeners about
        what they like and why
      </p>
      <div className='clip-filter__container'>
        <FilterOption
          {...filters[0]}
          onFilter={(userSelectedFilters) => {
            setFilters(userSelectedFilters);
            setClips(
              filterActualClips(
                actualClips,
                userSelectedFilters,
                setPagination,
              ).slice(0, 6),
            );
          }}
          selectedFilters={selectedFilters}
        />
        <FilterOption
          {...filters[1]}
          onFilter={(userSelectedFilters) => {
            setFilters(userSelectedFilters);
            setClips(
              filterActualClips(
                actualClips,
                userSelectedFilters,
                setPagination,
              ).slice(0, 6),
            );
          }}
          selectedFilters={selectedFilters}
        />
      </div>

      <div className='clips'>
        {clips.map((clip, k) => {
          offset += 100;
          if (k % 6 === 0) {
            offset = 0;
          }
          return (
            <Clip offset={offset} name={clip} onSetClip={setCurrentClip} />
          );
        })}
      </div>

      {filterActualClips(actualClips, selectedFilters).length > pagination && (
        <Button
          label='Load more'
          style={{ marginTop: '20px' }}
          onClick={() => {
            const newPagination = pagination + 6;
            setPagination(newPagination);
            setClips(
              filterActualClips(actualClips, selectedFilters).slice(
                0,
                newPagination,
              ),
            );
          }}
        />
      )}
    </Section>
  );
};

export default Container;
