import React from 'react';
import AbstractSegment from './AbstractSegment';
import Icon1 from '../../icons/quick-news-fix/Icon21.svg';
import Icon2 from '../../icons/quick-news-fix/Icon22.svg';
import Icon3 from '../../icons/quick-news-fix/Icon23.svg';
import Icon4 from '../../icons/quick-news-fix/Icon24.svg';

const QuickNewsFix = (props) => {
  const quotes = [
    {
      title: 'Be on the ball',
      body: 'News updates require swift content turnaround',
      icon: Icon1,
    },
    {
      title: 'News for everyone',
      body:
        'Covering topics regarded as news - current affairs, politics, sport and pop culture',
      icon: Icon2,
    },
    {
      title: 'Boost credibility',
      body: 'A credible host will create a trustworthy and authoritative tone',
      icon: Icon3,
    },
    {
      title: 'Ensure compatibility',
      body:
        'Across a whole range of devices e.g. car entertainment systems, TVs, tablets etc.',
      icon: Icon4,
    },
  ];

  return (
    <AbstractSegment
      id='quick-news-fix'
      slideInFrom='left'
      {...props}
      quotes={quotes}
    />
  );
};

export default QuickNewsFix;
