import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// CSS
import './index.css';
import './css/audio-switch.css';
import './css/block.css';
import './css/chevron.css';
import './css/container.css';
import './css/cta.css';
import './css/demand-space.css';
import './css/grid.css';
import './css/header.css';
import './css/help.css';
import './css/homepage.css';
import './css/hero.css';
import './css/logo.css';
import './css/menu.css';
import './css/nav.css';
import './css/overlay.css';
import './css/planet.css';
import './css/popover.css';
import './css/quote.css';
import './css/scroll.css';
import './css/section.css';
import './css/sticky.css';

import './css/welcome.css';

import './css/achieveing-a-goal.css';
import './css/indulge-my-passions.css';
import './css/take-me-away.css';
import './css/feel-good.css';
import './css/keep-me-company.css';
import './css/quick-news-fix.css';

import './css/clips.css';
import './css/reports.css';
import './css/resources.css';
import './css/contact.css';

import './css/mobile.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
