import React, { useState } from 'react';
import Overlay from '../components/Menu/Overlay';
import Menu from '../components/Menu/Menu';

const MenuIcon = () => {
  const [activeClass, toggle] = useState('');

  const toggleOverlay = () => {
    Boolean(activeClass) ? toggle() : toggle('amplify__menu--active');
  };

  return (
    <>
      <div className={`amplify__menu ${activeClass}`} onClick={toggleOverlay}>
        <svg width='78' height='78' viewBox='-5 -5 82 82'>
          <g>
            <circle id='b' cx='32' cy='32' r='32' />
            <rect id='d' x='18' y='24' width='27' height='4' rx='2' />
            <rect id='f' x='18' y='31' width='27' height='4' rx='2' />
            <rect id='h' x='18' y='38' width='27' height='4' rx='2' />
          </g>
        </svg>
      </div>

      <Overlay on={Boolean(activeClass)}>
        <Menu on={Boolean(activeClass)} onClick={toggleOverlay} />
      </Overlay>
    </>
  );
};

export default MenuIcon;
