import React from 'react';
import Section from '../../components/Section';

const Container = (props) => {
  return (
    <Section
      id='mobile'
      title="We're Sorry"
      body='This web application is optimised and designed for desktop experience only.'></Section>
  );
};

export default Container;
