import React from 'react';
import AbstractSegment from './AbstractSegment';
import Icon1 from '../../icons/feel-good/Icon13.svg';
import Icon2 from '../../icons/feel-good/Icon14.svg';
import Icon3 from '../../icons/feel-good/Icon15.svg';
import Icon4 from '../../icons/feel-good/Icon16.svg';

const FeelGood = (props) => {
  const quotes = [
    {
      title: 'All about easy listening',
      body:
        'Ensure all content in this area could be classified as ‘easy listening’',
      icon: Icon1,
    },
    {
      title: 'Chatty podcasts',
      body:
        'Casual conversations with likeable talent will play well in this space',
      icon: Icon2,
    },
    {
      title: 'Current comedy',
      body: 'Consider lighthearted weekly takes on current news',
      icon: Icon3,
    },
    {
      title: 'Help parents parent',
      body:
        "Assist with educational content, create family favorites to enjoy together and consider character-led fun kid's stories",
      icon: Icon4,
    },
  ];

  return (
    <AbstractSegment
      id='feel-good'
      slideInFrom='left'
      {...props}
      quotes={quotes}
    />
  );
};

export default FeelGood;
