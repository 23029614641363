import React, { useEffect } from 'react';
import AudibleLogo from '../../icons/audible-logo.svg';
import lottie from 'lottie-web';
import json from './logo.json';

const Logo = () => {
  useEffect(() => {
    const logo = document.getElementsByClassName('amplify__logo')[0];
    const animation = lottie.loadAnimation({
      container: logo,
      animationData: json,
      renderer: 'svg',
      loop: true,
      autoplay: false,
    });

    logo.addEventListener('mouseenter', () => {
      animation.play();
    });

    logo.addEventListener('mouseleave', () => {
      animation.stop();
    });
  }, []);

  const scrollToTop = () => {
    const el = document.getElementById('header');
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  return (
    <>
      <div
        className='amplify__logo amplify__logo--v2'
        onClick={scrollToTop}></div>
      <div className='audible__logo'>
        <img src={AudibleLogo} alt='Audible Logo' />
      </div>
    </>
  );
};

export default Logo;
