import React from 'react';

const BottomContent = (props) => {
  return (
    <div
      className={`primary`}
      style={{
        position: 'relative',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '40vh',
      }}>
      <div className='container grid' style={{ padding: '10px 0' }}>
        {props.quotes &&
          props.quotes.map((quote, k) => (
            <div className='secondary col-3' key={k}>
              <div>
                <img src={quote.icon} alt='Icon' style={{ width: '50px' }} />
              </div>
              <p className='secondary'>
                <strong>{quote.title}</strong>
              </p>
              <p className='secondary'>{quote.body}</p>
            </div>
          ))}
      </div>

      <div
        className='cta cta--square cta--primary'
        style={{ margin: '20px' }}
        onClick={() => {
          console.log(props);
          if (props.button.href) {
            window.open(props.button.href);
          }
        }}>
        <h4>{props.button.label}</h4>
      </div>
    </div>
  );
};

export default BottomContent;
