import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Button from '../../components/Button';
import Section from '../../components/Section';
import Chevron from '../../components/Chevron';
import reportData from '../../content/reports.json';

const filters = [
  {
    title: 'Country',
    spacing: '',
    options: [
      {
        name: 'Argentina',
        value: 'Argentina',
      },
      {
        name: 'Australia',
        value: 'Australia',
      },
      {
        name: 'Brazil',
        value: 'Brazil',
      },
      {
        name: 'Canada',
        value: 'Canada',
      },
      {
        name: 'Chile',
        value: 'Chile',
      },
      {
        name: 'Colombia',
        value: 'Colombia',
      },
      {
        name: 'Denmark',
        value: 'Denmark',
      },
      {
        name: 'France',
        value: 'France',
      },
      {
        name: 'Germany',
        value: 'Germany',
      },

      {
        name: 'Hong Kong',
        value: 'HongKong',
      },
      {
        name: 'India',
        value: 'India',
      },
      {
        name: 'Italy',
        value: 'Italy',
      },
      {
        name: 'Japan',
        value: 'Japan',
      },
      {
        name: 'Mexico',
        value: 'Mexico',
      },
      {
        name: 'The Netherlands',
        value: 'Netherlands',
      },
      {
        name: 'Norway',
        value: 'Norway',
      },

      {
        name: 'Singapore',
        value: 'Singapore',
      },
      {
        name: 'South Africa',
        value: 'South Africa',
      },
      {
        name: 'Spain',
        value: 'Spain',
      },
      {
        name: 'Sweden',
        value: 'Sweden',
      },
      {
        name: 'UAE',
        value: 'UAE',
      },
      {
        name: 'UK',
        value: 'UK',
      },
      {
        name: 'USA',
        value: 'USA',
      },
    ],
  },
  {
    title: 'Global',
    spacing: 'full',
    options: [
      {
        name: 'Global English',
        value: 'Global English',
      },
      {
        name: 'Regional EU',
        value: 'Regional EU',
      },
      {
        name: 'Regional LATAM',
        value: 'Regional LATAM',
      },
    ],
  },
];

const FilterOption = ({
  title,
  options,
  spacing = 'full',
  selectedFilters = [],
  onFilter = () => {},
}) => {
  const [open, toggle] = useState(true);

  const toggleFilter = () => {
    if (open) {
      toggle(false);
    } else {
      toggle(true);
    }
  };

  const classes = classnames({
    open: open,
    cssanimation: true,
    fadeInTop: open,
    fadeOutTop: open !== null && !open,
  });

  return (
    <div className='report-filter'>
      <div onClick={toggleFilter}>
        <h4 className='secondary'>{title}</h4>
        <Chevron open={open} />
      </div>
      <form>
        <ul className={classes}>
          {options.map((option) => (
            <li className={spacing}>
              <input
                type='checkbox'
                name={`reports-${option.value}`}
                id={`reports-${option.value}`}
                value={`${option.value}`}
                onChange={(e) => {
                  const index = selectedFilters.indexOf(e.target.value);
                  if (e.target.checked === false && index > -1) {
                    const localFilters = [...selectedFilters];
                    localFilters.splice(index, 1);
                    onFilter(localFilters);
                    console.log('remove', e.target.value, localFilters);
                  } else if (e.target.checked === true) {
                    const localFilters = [...selectedFilters];
                    localFilters.push(e.target.value);
                    onFilter(localFilters);
                    console.log(localFilters);
                  }
                }}
              />
              <label className='secondary' htmlFor={`reports-${option.value}`}>
                {option.name}
              </label>
            </li>
          ))}
        </ul>
      </form>
    </div>
  );
};

const Report = ({ offset, name, filter, download }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, offset);
  }, [opacity, offset]);

  const PREFIX = 'https://sparkler-amplify.s3.eu-west-2.amazonaws.com/reports/';

  return (
    <div
      className='report'
      style={{ opacity }}
      data-filter={filter}
      data-download={download}>
      <p className='secondary'>
        {filter} - {name}
      </p>
      <Button
        label='Download'
        type='secondary'
        size='mini'
        onClick={() => window.open(`${PREFIX}${download}`)}
      />
    </div>
  );
};

const actualreports = [...reportData];

const filterActualReports = (
  clips,
  selectedFilters,
  setPagination = () => {},
) => {
  setPagination(12);

  if (selectedFilters.length === 0) {
    return clips;
  }

  const filteredReports = clips.filter((clip) => {
    return selectedFilters.indexOf(clip[1]) > -1;
  });

  return filteredReports;
};

const Container = (props) => {
  const [pagination, setPagination] = useState(12);
  const [selectedFilters, setFilters] = useState([]);
  const [reports, setReports] = useState(
    filterActualReports(actualreports, selectedFilters).slice(0, pagination),
  );

  let offset = 0;

  return (
    <Section
      id='reports'
      title='Reports'
      body='Here you will find all of the Amplify reports. We have included the Quantitative reports and datasheets, the Qualitative reports, and the Wrap up presentations.'>
      <div className='report-filter__container'>
        <FilterOption
          {...filters[0]}
          onFilter={(userSelectedFilters) => {
            setFilters(userSelectedFilters);
            setReports(
              filterActualReports(
                actualreports,
                userSelectedFilters,
                setPagination,
              ).slice(0, 12),
            );
          }}
          selectedFilters={selectedFilters}
        />
        <FilterOption
          {...filters[1]}
          onFilter={(userSelectedFilters) => {
            setFilters(userSelectedFilters);
            setReports(
              filterActualReports(
                actualreports,
                userSelectedFilters,
                setPagination,
              ).slice(0, 12),
            );
          }}
          selectedFilters={selectedFilters}
        />
      </div>

      <div className='reports'>
        {reports.map((report, k) => {
          offset += 100;
          if (k % 12 === 0) {
            offset = 0;
          }
          return (
            <Report
              offset={offset}
              name={report[2]}
              filter={report[1]}
              download={Object.values(report).join(' - ').replace(/ /g, '+')}
            />
          );
        })}
      </div>

      {filterActualReports(actualreports, selectedFilters).length >
        pagination && (
        <Button
          label='Load more'
          style={{ marginTop: '20px' }}
          onClick={() => {
            const newPagination = pagination + 12;
            setPagination(newPagination);
            setReports(
              filterActualReports(actualreports, selectedFilters).slice(
                0,
                newPagination,
              ),
            );
          }}
        />
      )}
    </Section>
  );
};

export default Container;
