import React, { useContext, useState, useEffect } from 'react';
import { AudioContext } from '../../../context/AudioContext';
import classnames from 'classnames';
import BottomContent from '../BottomContent';
import Plant1 from '../../../icons/plant-1.svg';
import Plant2 from '../../../icons/plant-2.svg';
import Clock from '../../../icons/clock.svg';
import Cat from '../../../icons/cat.svg';
import Frame1 from '../../../icons/frame-1.svg';
import Frame2 from '../../../icons/frame-2.svg';
import Frame3 from '../../../icons/frame-3.svg';
import Frame4 from '../../../icons/frame-4.svg';
import Man from '../../../icons/man.svg';
import Table from '../../../icons/side-table.svg';
import Window from '../../../icons/window.svg';
import Woman from '../../../icons/woman.svg';
import Icon1 from '../../../icons/achieving-a-goal/Icon1.svg';
import Icon2 from '../../../icons/achieving-a-goal/Icon2.svg';
import Icon3 from '../../../icons/achieving-a-goal/Icon3.svg';
import Icon4 from '../../../icons/achieving-a-goal/Icon4.svg';

const V2 = ({ title, subtitle, quote, button, color, audio }) => {
  const { setAudioSource } = useContext(AudioContext);
  const [played, setPlayed] = useState(false);
  const [inView, setInView] = useState(false);
  const [windowOpacity] = useState(1);
  const [mamalOpacity] = useState(1);
  const [furnitureOpacity] = useState(1);
  const [catClassName] = useState({
    cat: true,
  });
  const [womanClassName] = useState({
    woman: true,
  });
  const [manClassName] = useState({
    man: true,
  });
  const [quoteClassNames, setQuoteClassName] = useState({
    container: true,
    grid: true,
    cssanimation: true,
    fadeInBottom: false,
    fadeOutBottom: true,
  });

  const [furnitureOffset, setFurnitureOffset] = useState(0);
  const [mamalsOffset, setMamalsOffset] = useState(0);
  const [windowsOffset, setWindowsOffset] = useState(0);

  const windows = {
    opacity: windowOpacity,
    transform: `translate3d(${windowsOffset}px, 0px, 0px)`,
  };
  const mamals = {
    opacity: mamalOpacity,
    transform: `translate3d(${mamalsOffset}px, 0px, 0px)`,
  };
  const furniture = {
    opacity: furnitureOpacity,
    transform: `translate3d(${furnitureOffset}px, 0px, 0px)`,
  };

  const quotes = [
    {
      title: 'Content chunking',
      body:
        'Create content with 20-30 minute long chunks for maximum absorption',
      icon: Icon1,
    },
    {
      title: 'Snappy titles',
      body:
        'Label content with action and output oriented titles e.g. How to…, 5 steps to…, 10 hacks for…',
      icon: Icon2,
    },
    {
      title: 'Learning for all',
      body:
        'Commission content in three areas: formal learning, career/business advancement skills and directional personal development',
      icon: Icon3,
    },
    {
      title: 'Find experts',
      body:
        'Individuals with credentials in the field with an informative, inclusive and motivational tone add credibility',
      icon: Icon4,
    },
  ];

  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        const segment = document.getElementById('achieving-a-goal');
        const currentPosition = window.pageYOffset;
        const elementOffset = segment.offsetTop;
        const calculation = currentPosition >= elementOffset;
        const MAX_PERC = 100;
        const percAway = currentPosition / (elementOffset / 100);
        const reverse = MAX_PERC - percAway;

        let FURNITURE = reverse * 10;
        let WINDOWS = reverse * 7.5;
        let MAMALS = reverse * 5;

        if (FURNITURE < 0) {
          FURNITURE = 0;
        }

        if (WINDOWS < 0) {
          WINDOWS = 0;
        }

        if (MAMALS < 0) {
          MAMALS = 0;
        }

        setFurnitureOffset(FURNITURE);
        setMamalsOffset(MAMALS);
        setWindowsOffset(WINDOWS);

        if (calculation) {
          setInView(true);
        }

        if (percAway > 95) {
          setQuoteClassName({
            container: true,
            grid: true,
            cssanimation: true,
            fadeInBottom: true,
            fadeOutBottom: false,
          });
        } else {
          setQuoteClassName({
            container: true,
            grid: true,
            cssanimation: true,
            fadeInBottom: false,
            fadeOutBottom: true,
          });
        }
      },
      false,
    );
  }, []);

  if (inView && !played) {
    setPlayed(true);
    setAudioSource(audio);
  }

  return (
    <section id='achieving-a-goal' className={`section ${color} segment`}>
      <div className='outer-container'>
        <div className='sticky'>
          <div className='section-container'>
            <div className='container grid'>
              <h1 className='col-7'>{title}</h1>
            </div>
            <div className='container grid'>
              <p className='col-6'>{subtitle}</p>
            </div>
            <div className={classnames(quoteClassNames)}>
              <div className='quote__container col-4'>
                <p className='quote'>
                  I want to take advantage of every free moment and be
                  productive. Mental well-being podcasts give me practical
                  skills to develop my mind and grow as a person.
                </p>
              </div>
            </div>

            <div className='left-side'>
              <div id='scene'>
                <img
                  className='plant'
                  src={Plant2}
                  alt='Plant'
                  style={furniture}
                />
                <img
                  className={classnames(womanClassName)}
                  src={Woman}
                  alt='Woman'
                  style={mamals}
                />
                <img
                  className={classnames(catClassName)}
                  src={Cat}
                  alt='Cat'
                  style={mamals}
                />
                <img
                  className='frame'
                  src={Frame1}
                  alt='Frame'
                  style={windows}
                />
                <img
                  className='frame2'
                  src={Frame2}
                  alt='Frame'
                  style={windows}
                />
                <img
                  className='frame3'
                  src={Frame3}
                  alt='Frame'
                  style={windows}
                />
                <img
                  className='frame4'
                  src={Frame4}
                  alt='Frame'
                  style={windows}
                />
              </div>
            </div>
            <div className='right-side'>
              <div>
                <img
                  className='table'
                  src={Table}
                  alt='Table'
                  style={furniture}
                />
                <img
                  className={classnames(manClassName)}
                  src={Man}
                  alt='Man'
                  style={mamals}
                />
                <img
                  className='window'
                  src={Window}
                  alt='Window'
                  style={windows}
                />
                <img
                  className='plant'
                  src={Plant1}
                  alt='Plant'
                  style={furniture}
                />
                <img
                  className='clock'
                  src={Clock}
                  alt='Clock'
                  style={furniture}
                />
              </div>
            </div>
          </div>

          <BottomContent button={button} quotes={quotes} />
        </div>
      </div>
    </section>
  );
};

export default V2;
