import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import { AudioProvider } from './context/AudioContext';
import Styleguide from './components/Styleguide';
import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/styleguide'>
          <Styleguide />
        </Route>
        <Route path='/dev'>
          <AudioProvider>
            <Home dev={true} />
          </AudioProvider>
        </Route>
        <Route path='/'>
          <AudioProvider>
            <Home />
          </AudioProvider>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
