import React, { useContext, useState, useEffect } from 'react';
import { AudioContext } from '../../context/AudioContext';
import classnames from 'classnames';
import BottomContent from './BottomContent';

const AbstractSegment = ({
  id,
  slideInFrom = 'right',
  title,
  subtitle,
  quote,
  button,
  color,
  quotes = [],
  audio,
}) => {
  const { setAudioSource } = useContext(AudioContext);
  const [played, setPlayed] = useState(false);
  const [inView, setInView] = useState(false);
  const [backgroundOpacity] = useState(1);
  const [foregroundOpacity] = useState(1);
  const [LeftBg, setLeftBg] = useState(null);
  const [Left, setLeft] = useState(null);
  const [RightBg, setRightBg] = useState(null);
  const [Right, setRight] = useState(null);

  const [quoteClassNames, setQuoteClassName] = useState({
    container: true,
    grid: true,
    cssanimation: true,
    fadeInBottom: false,
    fadeOutBottom: true,
  });

  const [backgroundOffset, setBackgroundOffset] = useState(0);
  const [foregroundOffset, setForegroundOffset] = useState(0);

  const background = {
    opacity: backgroundOpacity,
    transform: `translate3d(${
      slideInFrom === 'left' ? '-' : ''
    }${backgroundOffset}px, 0px, 0px)`,
  };

  const foreground = {
    opacity: foregroundOpacity,
    transform: `translate3d(${
      slideInFrom === 'left' ? '-' : ''
    }${foregroundOffset}px, 0px, 0px)`,
  };

  import(`../../icons/${id}/left-bg.svg`).then((module) => {
    const { default: img } = module;
    setLeftBg(img);
  });

  import(`../../icons/${id}/left.svg`).then((module) => {
    const { default: img } = module;
    setLeft(img);
  });

  import(`../../icons/${id}/right-bg.svg`).then((module) => {
    const { default: img } = module;
    setRightBg(img);
  });

  import(`../../icons/${id}/right.svg`).then((module) => {
    const { default: img } = module;
    setRight(img);
  });

  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        const segment = document.getElementById(id);
        const currentPosition = window.pageYOffset;
        const elementOffset = segment.offsetTop;
        const calculation = currentPosition >= elementOffset;
        const MAX_PERC = 100;
        const percAway = currentPosition / (elementOffset / 100);
        const reverse = MAX_PERC - percAway;

        let BACKGROUND = reverse * 7.5;
        let FOREGROUND = reverse * 12.5;

        if (BACKGROUND < 0) {
          BACKGROUND = 0;
        }

        if (FOREGROUND < 0) {
          FOREGROUND = 0;
        }

        setBackgroundOffset(BACKGROUND);
        setForegroundOffset(FOREGROUND);

        if (calculation) {
          setInView(true);
        }

        if (percAway > 95) {
          setQuoteClassName({
            container: true,
            grid: true,
            cssanimation: true,
            fadeInBottom: true,
            fadeOutBottom: false,
          });
        } else {
          setQuoteClassName({
            container: true,
            grid: true,
            cssanimation: true,
            fadeInBottom: false,
            fadeOutBottom: true,
          });
        }
      },
      false,
    );
  }, []);

  if (inView && !played) {
    setPlayed(true);
    setAudioSource(audio);
  }

  return (
    <section id={id} className={`section ${color} segment`}>
      <div className='outer-container'>
        <div className='sticky'>
          <div className='section-container'>
            <div className='container grid'>
              <h1 className='col-7'>{title}</h1>
            </div>
            <div className='container grid'>
              <p className='col-6'>{subtitle}</p>
            </div>
            <div className={classnames(quoteClassNames)}>
              <div className='quote__container col-4'>
                <p className='quote'>{quote.body}</p>
              </div>
            </div>

            <div className='left-side'>
              <div id='scene'>
                <img
                  className='left-bg'
                  src={LeftBg}
                  alt='Left Background'
                  style={background}
                />
                <img
                  className='left'
                  src={Left}
                  alt='Left'
                  style={foreground}
                />
              </div>
            </div>
            <div className='right-side'>
              <div id='scene'>
                <img
                  className='right-bg'
                  src={RightBg}
                  alt='Right Background'
                  style={background}
                />
                <img
                  className='right'
                  src={Right}
                  alt='Right'
                  style={foreground}
                />
              </div>
            </div>
          </div>
          <BottomContent button={button} quotes={quotes} />
        </div>
      </div>
    </section>
  );
};

export default AbstractSegment;
