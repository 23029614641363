import React from 'react';

const Overlay = ({
  children,
  on = false,
  opacity = 1,
  onClick = () => {},
  transition = '0.5s',
  el = null,
}) => {
  return (
    <div
      className={`overlay ${el ? `overlay--${el}` : ''} ${
        on ? 'overlay__on' : ''
      }`}
      style={{ opacity: opacity || 1, transition: `opacity ${transition}` }}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default Overlay;
