import React, { useContext, useState, useEffect } from 'react';
import { AudioContext } from '../../context/AudioContext';
import Planet from '../../icons/planet.svg';
import CloudsLeft from '../../icons/clouds-left.svg';
import CloudsRight from '../../icons/clouds-right.svg';

function Welcome() {
  const { setAudioSource } = useContext(AudioContext);
  const [played, setPlayed] = useState(false);
  const [inView, setInView] = useState(false);
  const [offset, setOffset] = useState(275);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    let o = offset;

    window.addEventListener(
      'scroll',
      () => {
        const section = document.getElementById('what-is-amplify');
        const currentPosition = window.pageYOffset;
        const elementOffset = section.offsetTop;
        const calculation = currentPosition >= elementOffset;
        const height = section.offsetHeight;

        if (calculation) {
          setInView(true);
        }

        const calc1 = currentPosition - elementOffset;
        const calc2 = height - elementOffset;
        const currentPerc = (calc1 / calc2) * 100;
        const onePerc = 275 / 100;
        const perc = onePerc * currentPerc;
        const calc = 275 - perc;

        if (calc1 > 0 && currentPerc <= 100) {
          o = calc;
        } else if (calc <= 0) {
          o = 0;
        } else {
          o = 275;
        }

        setOpacity(0);

        if (currentPerc > 40) {
          setOpacity(1);
        }

        setOffset(o);
      },
      false,
    );
  }, []);

  if (inView && !played) {
    setPlayed(true);
    setAudioSource(
      '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+02.mp3',
    );
  }

  return (
    <>
      <section id='what-is-amplify' className='section primary hero welcome'>
        <div className='container'>
          <div className='sticky'>
            <h1>What is Amplify?</h1>
            <h3 className='secondary' style={{ opacity: 1 }}>
              Amplify is a framework created to understand the landscape of
              spoken word audio from around the globe.
            </h3>
            <section className='planet__container'>
              <img
                src={CloudsLeft}
                className='clouds clouds--left'
                alt='Clouds'
                style={{ left: `${offset}px` }}
              />
              <img src={Planet} className='planet' alt='Planet' />
              <img
                src={CloudsRight}
                className='clouds clouds--right'
                alt='Clouds'
                style={{ right: `${offset}px` }}
              />
            </section>

            <h3 className='secondary' style={{ opacity }}>
              So we’ve crafted this microsite to guide you through the world of
              Amplify, show you the insight we’ve unearthed, and teach you how
              to use it.
            </h3>
            <h3 className='secondary' style={{ opacity }}>
              From localized nuances to global perspectives, we’ve identified
              six major opportunity areas for content.
            </h3>
            <h3 className='secondary' style={{ opacity }}>
              And we call these our Demand Spaces.
            </h3>
          </div>
        </div>
      </section>
    </>
  );
}

export default Welcome;
