import React, { useState } from 'react';
import V1 from './versions/V1';
import V2 from './versions/V2';

const VERSIONS = {
  ONE: 1,
  TWO: 2,
};

const AchievingAGoal = (props) => {
  const [ACTIVE_VERSION] = useState(2);

  switch (ACTIVE_VERSION) {
    case VERSIONS.ONE:
      return <V1 {...props} />;

    case VERSIONS.TWO:
    default:
      return <V2 {...props} />;
  }
};

export default AchievingAGoal;
