import React from 'react';
import Cross from '../../icons/cross.svg';

const Popover = ({ on, onClose }) => {
  return (
    <div className={`popover primary ${on ? 'on' : ''}`}>
      <img src={Cross} alt='Close' className='close' onClick={onClose} />
      <span className='arrow'></span>
      <p>
        Please enable <strong>audio</strong> <br />
        for optimal experience
      </p>
    </div>
  );
};

export default Popover;
