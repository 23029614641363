import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import Section from '../../components/Section';

const Resource = ({ offset, title, download }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, offset);
  }, [opacity, offset]);

  return (
    <div className='resource' style={{ opacity }}>
      <p className='secondary'>{title}</p>
      <Button
        label='Download'
        type='secondary'
        size='mini'
        onClick={() => window.open(`${download.url}`)}
      />
    </div>
  );
};

const filterActualResources = (
  resources,
  selectedFilters,
  setPagination = () => {},
) => {
  setPagination(12);

  return resources;
};

const Container = (props) => {
  const [pagination, setPagination] = useState(12);
  const [selectedFilters] = useState([]);
  const [resourcesFromContentful, setContentfulResources] = useState([]);
  const [resources, setResources] = useState(
    filterActualResources(resourcesFromContentful, selectedFilters).slice(
      0,
      pagination,
    ),
  );

  let offset = 0;

  useEffect(() => {
    fetch(
      `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENT_SPACE_ID}/environments/master/entries?access_token=${process.env.REACT_APP_CONTENT_DELIVERY_ACCESS_TOKEN}&content_type=resources&include=1`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const mapped = data.items.map((item) => {
          return {
            title: item.fields.title,
            download: data.includes.Asset.find(
              (asset) => item.fields.download.sys.id === asset.sys.id,
            ).fields.file,
          };
        });

        setContentfulResources(mapped);
        setResources(mapped);
      });
  }, []);

  return (
    <Section
      id='resources'
      title='Resources'
      body='This section is brings together the most useful and relevant additional resources that Amplify can assist you with.'>
      <div className='resources'>
        {resources.map((resource, k) => {
          offset += 100;
          if (k % 12 === 0) {
            offset = 0;
          }
          return (
            <Resource
              key={k}
              offset={offset}
              filter={resource[1]}
              {...resource}
            />
          );
        })}
      </div>

      {filterActualResources(resourcesFromContentful, selectedFilters).length >
        pagination && (
        <Button
          label='Load more'
          style={{ marginTop: '20px' }}
          onClick={() => {
            const newPagination = pagination + 12;
            setPagination(newPagination);
            setResources(
              filterActualResources(
                resourcesFromContentful,
                selectedFilters,
              ).slice(0, newPagination),
            );
          }}
        />
      )}
    </Section>
  );
};

export default Container;
