import React from 'react';
import Homepage from '../Homepage';
import Logo from '../Logo/Logo';
import MenuIcon from '../../icons/Menu';
import AudioSwitch from '../Audio';

const Header = () => {
  return (
    <header id='header' className='amplify__header'>
      <Logo />
      <Homepage />
      <MenuIcon />
      <AudioSwitch />
    </header>
  );
};

export default Header;
