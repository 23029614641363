import React from 'react';
import AbstractSegment from './AbstractSegment';
import Icon1 from '../../icons/keep-me-company/Icon17.svg';
import Icon2 from '../../icons/keep-me-company/Icon18.svg';
import Icon3 from '../../icons/keep-me-company/Icon19.svg';
import Icon4 from '../../icons/keep-me-company/Icon20.svg';

const KeepMeCompany = (props) => {
  const quotes = [
    {
      title: 'Identify cross-over pieces',
      body:
        'Identify content from other Demand Spaces which could be utilized here',
      icon: Icon1,
    },
    {
      title: 'Undemanding content',
      body:
        'Conversational styles like talk shows which don’t demand much will play well',
      icon: Icon2,
    },
    {
      title: 'Rollover content',
      body:
        'Mimic the radio style and allow content to continue automatically with no intervention necessary',
      icon: Icon3,
    },
    {
      title: 'Partner with devices',
      body:
        'Be top of mind when listeners get a new device such as smart / connected speakers',
      icon: Icon4,
    },
  ];

  return (
    <AbstractSegment
      id='keep-me-company'
      slideInFrom='right'
      quotes={quotes}
      {...props}
    />
  );
};

export default KeepMeCompany;
