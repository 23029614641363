import React from 'react';
import Header from '../../components/Header';
import Welcome from '../Welcome';
import DemandSpace from '../DemandSpace';
import Help from '../Help';
import BlockContainer from '../../components/Block/Container';
import SegmentContainer from '../Segments/Container';
import ScrollPrompt from '../../components/ScrollPrompt/ScrollPrompt';
import Clips from '../Clips';
import Reports from '../Reports';
import Resources from '../Resources';
import Contact from '../Contact';
import Mobile from '../Mobile';
import FPSStats from 'react-fps-stats';

function Home({ dev }) {
  return (
    <div className='amplify'>
      <div className='amplify__container'>
        <Header />
        <Welcome />
        <DemandSpace />
        <Help />
        <BlockContainer />
        <SegmentContainer />
        <Clips />
        <Reports />
        <Resources />
        <Contact />
        <Mobile />
      </div>
      <ScrollPrompt />
      {dev === true && (
        <div>
          <FPSStats top={'auto'} left={'auto'} bottom={0} right={0} />
        </div>
      )}
    </div>
  );
}

export default Home;
