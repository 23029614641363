import React, { useContext, useState, useEffect } from 'react';
import { AudioContext } from '../../context/AudioContext';
import Ironing from '../../icons/ironing.svg';
import Background from '../../icons/background.svg';
import Speaker from '../../icons/speaker.svg';

const DemandSpace = () => {
  const { setAudioSource } = useContext(AudioContext);
  const [played, setPlayed] = useState(false);
  const [inView, setInView] = useState(false);
  const [offset, setOffset] = useState(0);
  const [circle1, setCircle1] = useState(0);
  const [circle2, setCircle2] = useState(0);
  const [circle3, setCircle3] = useState(0);

  useEffect(() => {
    let o = offset;

    window.addEventListener(
      'scroll',
      () => {
        const demandSpace = document.getElementById(
          'demand-spaces-information',
        );
        const currentPosition = window.pageYOffset;
        const elementOffset = demandSpace.offsetTop;
        const calculation = currentPosition >= elementOffset;
        const MAX_LEFT = 320;

        if (calculation) {
          setInView(true);
          const onePerc = MAX_LEFT / 100;
          const calc = currentPosition - elementOffset;
          const currentPosPerc = calc / (800 / 100);
          const actualCalc = onePerc * currentPosPerc;
          const finalCalc = actualCalc;
          const percOffset = onePerc * 100;

          if (currentPosPerc > 25) {
            setCircle1(1);
          } else {
            setCircle1(0);
          }

          if (currentPosPerc > 50) {
            setCircle2(1);
          } else {
            setCircle2(0);
          }

          if (currentPosPerc > 75) {
            setCircle3(1);
          } else {
            setCircle3(0);
          }

          if (currentPosPerc > 100 && finalCalc > 0 && currentPosPerc <= 200) {
            if (currentPosPerc > 200) {
              o = MAX_LEFT;
            } else {
              o = finalCalc - percOffset;
            }
          } else if (currentPosPerc > 200) {
            o = MAX_LEFT;
          } else {
            o = 0;
          }

          setOffset(o);
        }
      },
      false,
    );
  }, []);

  if (inView && !played) {
    setPlayed(true);
    setAudioSource(
      '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+03.mp3',
    );
  }

  return (
    <section
      id='demand-spaces-information'
      className='section primary hero demand-space'>
      <div className='container'>
        <div className='sticky'>
          <h3 className='secondary'>A Demand Space is based on 3 dimensions</h3>
          <div className='venn half'>
            <div
              className='circle'
              style={{ zIndex: 30, left: `${offset}px`, opacity: circle1 }}>
              <img src={Ironing} alt='Man Ironing' />
            </div>
            <div className='circle' style={{ zIndex: 20, opacity: circle2 }}>
              <img src={Background} alt='Home Background' />
            </div>
            <div
              className='circle'
              style={{ zIndex: 10, right: `${offset}px`, opacity: circle3 }}>
              <img src={Speaker} alt='Home Background' />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '40px',
            }}>
            <div style={{ opacity: circle1 }}>
              <h2>MOTIVATION</h2>
              <p>
                What are the different needs and emotions which people are
                trying to fulfil by listening
              </p>
            </div>
            <div style={{ opacity: circle2 }}>
              <h2>ENVIRONMENT</h2>
              <p>
                What are the occasions when people want to listen, who are they
                with and what else are they doing
              </p>
            </div>
            <div style={{ opacity: circle3 }}>
              <h2>CONTENT</h2>
              <p>
                What are peoples’ preferences when it comes to the different
                genres and formats they like to listen to
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemandSpace;
