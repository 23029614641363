import React from 'react';
import AbstractSegment from './AbstractSegment';
import Icon1 from '../../icons/take-me-away/Icon9.svg';
import Icon2 from '../../icons/take-me-away/Icon10.svg';
import Icon3 from '../../icons/take-me-away/Icon11.svg';
import Icon4 from '../../icons/take-me-away/Icon12.svg';

const TakeMeAway = (props) => {
  const quotes = [
    {
      title: 'Content for immersion',
      body:
        'Continue to develop content which can create an intense and absorbing atmosphere',
      icon: Icon1,
    },
    {
      title: 'Vary the formats',
      body:
        'Include nostalgic audiobooks, ‘page turning’ podcasts and celebrity voiced audio-dramas in the roster',
      icon: Icon2,
    },
    {
      title: 'Promote future zeitgeist pieces',
      body: 'Such as news-worthy first-person narratives or true crime',
      icon: Icon3,
    },
    {
      title: 'Redevelop old favorites',
      body:
        'Draw on listeners’ needs to disconnect with nostalgia by redeveloping fan favorites',
      icon: Icon4,
    },
  ];

  return (
    <AbstractSegment
      id='take-me-away'
      slideInFrom='right'
      quotes={quotes}
      {...props}
    />
  );
};

export default TakeMeAway;
