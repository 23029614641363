import React, { createContext, useEffect, useState } from 'react';

export const AudioContext = createContext({
  audioEnabled: false,
  audio: null,
  audioSource:
    '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+01.mp3',
});

export function AudioProvider({ children }) {
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [audio, setAudio] = useState(null);
  const [audioSource, setAudioSource] = useState(
    '//sparkler-amplify.s3.eu-west-2.amazonaws.com/audio/AMPLIFY+VOICE+SECTION+01.mp3',
  );

  useEffect(() => {
    const audio = document.getElementsByTagName('audio')[0];
    setAudio(audio);
  }, []);

  return (
    <AudioContext.Provider
      value={{
        audio,
        setAudio,
        audioSource,
        setAudioSource,
        audioEnabled,
        setAudioEnabled,
      }}>
      {children}
    </AudioContext.Provider>
  );
}
