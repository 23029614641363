import React, { useState } from 'react';
import classnames from 'classnames';

const Overlay = ({ on, children }) => {
  const [overlayEnabled, enableOverlay] = useState(false);
  const classes = classnames({
    overlay: true,
    overlay__on: overlayEnabled,
  });

  if (Boolean(on)) {
    setTimeout(() => {
      enableOverlay(true);
    }, 500);
  } else {
    setTimeout(() => {
      enableOverlay(false);
    }, 200);
  }

  return (
    <>
      <div className={classes}>{children}</div>
      <div
        className='overlay__circle'
        style={{ transform: `scale(${Boolean(on) ? 100 : 0})` }}></div>
    </>
  );
};

export default Overlay;
