import React, { useState, useEffect, useContext } from 'react';
import { AudioContext } from '../../context/AudioContext';
import Overlay from '../Overlay';
import lottie from 'lottie-web';
import off from './off.json';
import Popover from '../Popover';

const AudioSwitch = () => {
  const { audioEnabled, setAudioEnabled, audio, audioSource } = useContext(
    AudioContext,
  );
  const [showOverlay, enabledOverlay] = useState(true);
  const [animation, setAnimation] = useState(null);

  useEffect(() => {
    setAnimation(
      lottie.loadAnimation({
        container: document.getElementById('audio-switch'),
        animationData: off,
        renderer: 'svg',
        loop: false,
        autoplay: false,
      }),
    );
  }, []);

  if (audio && audioSource) {
    try {
      audio.pause();
      const source = document.getElementById('audioSource');
      source.src = audioSource;
      audio.currentTime = 0;
      audio.load();
    } catch (e) {}
  }

  if (audio && audioEnabled) {
    setTimeout(() => {
      audio.play();
    }, 1000);
  } else if (audio && !audioEnabled) {
    setTimeout(() => {
      audio.pause();
    }, 1000);
  }

  const onClick = () => {
    enabledOverlay(false);
  };

  return (
    <>
      <div
        id='audio-switch'
        onClick={() => {
          enabledOverlay(false);
          if (animation) {
            if (audioEnabled) {
              animation.setDirection(-1);
              setAudioEnabled(false);
            } else {
              animation.setDirection(1);
              setAudioEnabled(true);
            }
            animation.play();
          }
        }}></div>
      <Overlay
        on={showOverlay}
        opacity={0.8}
        onClick={onClick}
        transition='0.1s'
        el='audio-switch'
      />
      <Popover on={showOverlay} onClose={onClick} />
      <audio controls>
        <source id='audioSource' src={audioSource} type='audio/mpeg' />
      </audio>
    </>
  );
};

export default AudioSwitch;
