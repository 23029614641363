import React from 'react';
import AbstractSegment from './AbstractSegment';
import Icon1 from '../../icons/indulge-my-passions/Icon5.svg';
import Icon2 from '../../icons/indulge-my-passions/Icon6.svg';
import Icon3 from '../../icons/indulge-my-passions/Icon7.svg';
import Icon4 from '../../icons/indulge-my-passions/Icon8.svg';

const IndulgeMyPassions = (props) => {
  const quotes = [
    {
      title: 'Commission for broad appeal',
      body:
        'Areas like news, self-development and comedy can become easy favorites for listeners',
      icon: Icon1,
    },
    {
      title: 'Develop deep wells',
      body:
        'Find content for enthusiasts in areas like history, sport, technology, science, fashion & beauty',
      icon: Icon2,
    },
    {
      title: 'Find hosts with charisma',
      body:
        'Strong personalities with entertaining and informative tones will keep listeners coming back',
      icon: Icon3,
    },
    {
      title: 'Build profile on social',
      body:
        'Take advantage of the desire to connect and share by linking with social media and YouTube',
      icon: Icon4,
    },
  ];

  return (
    <AbstractSegment
      id='indulge-my-passions'
      slideInFrom='left'
      {...props}
      quotes={quotes}
    />
  );
};

export default IndulgeMyPassions;
