import React from 'react';
import Section from '../../components/Section';
import SparklerLogo from '../../icons/sparkler.svg';
import AudibleLogo from '../../icons/audible.svg';
import Ben from '../../icons/contacts/Ben.svg';

const Container = (props) => {
  return (
    <Section id='contact' title='Contact' className='royal-blue'>
      <div className='contact__container'>
        <div className='contact'>
          <div className='contact__image'>
            <img src={Ben} alt='Ben Robins' />
          </div>
          <h4 className='contact__name secondary'>Ben Robins</h4>
          <p className='contact__title secondary'>
            Global Director, Content Research <br />
            <span>
              <a className='secondary' href='mailto:berobins@audible.co.uk'>
                berobins@audible.co.uk
              </a>
            </span>
          </p>
        </div>
      </div>
      <p>
        <a href='https://www.audible.co.uk/' target='_blank' rel='noreferrer'>
          <img
            src={AudibleLogo}
            alt='Audible Logo'
            style={{ width: '250px' }}
          />
        </a>
      </p>
      <p className='secondary'>Insights and design by</p>
      <p>
        <a href='https://www.sparkler.co.uk' target='_blank' rel='noreferrer'>
          <img
            src={SparklerLogo}
            alt='Sparkler Logo'
            style={{ width: '400px' }}
          />
        </a>
      </p>
      <p className='secondary'>
        Market insights specialists. <br />
        Sparkler shines a light on the human side of the digital world.
      </p>
    </Section>
  );
};

export default Container;
