import React from 'react';
import Family from '../icons/family.svg';

function Styleguide() {
  return (
    <div className='App'>
      <section className='section primary'>
        <div className='container'>
          <h1 style={{ color: 'white' }}>Heading One</h1>
          <h2 style={{ color: 'white' }}>Heading Two</h2>
          <p style={{ color: 'white' }}>Body content</p>
          <p className='quote' style={{ color: 'white' }}>
            Quotes
          </p>
        </div>
      </section>
      <section className='section blue'>
        <h1>Buttons</h1>
        <div className='container'>
          <div className='cta cta--primary'>Primary</div>
          <div className='cta cta--secondary'>Secondary</div>
        </div>
        <div className='container'>
          <div className='cta cta--square cta--primary'>
            <h4>Primary</h4>
          </div>
          <div className='cta cta--square cta--secondary'>
            <h4>Secondary</h4>
          </div>
        </div>
      </section>

      <section className='section red'>
        <h1>Circle Components</h1>
        <div class='help__container'>
          <div className='help'>Help</div>
          <div className='help'>Explore</div>
          <div className='help'>Answer</div>
          <div className='help'>Direct</div>
        </div>
      </section>

      <section className='section green'>
        <h1>Quotes</h1>
        <section className='grid' style={{ marginTop: '100px' }}>
          <div className='quote__container col-4'>
            <img src={Family} alt='Couple' className='icon' />
            <p class='quote'>
              I want to take advantage of every free moment and be productive.
              Mental well-being podcasts give me practical skills to develop my
              mind and grow as a person.
            </p>
            <p className='author'>Barcelona, Young Family, AAG</p>
          </div>
          <div className='quote__container col-4'>
            <img src={Family} alt='Couple' className='icon' />
            <p class='quote'>
              I want to take advantage of every free moment and be productive.
              Mental well-being podcasts give me practical skills to develop my
              mind and grow as a person.
            </p>
            <p className='author'>Barcelona, Young Family, AAG</p>
          </div>
          <div className='quote__container col-4'>
            <img src={Family} alt='Couple' className='icon' />
            <p class='quote'>
              I want to take advantage of every free moment and be productive.
              Mental well-being podcasts give me practical skills to develop my
              mind and grow as a person.
            </p>
            <p className='author'>Barcelona, Young Family, AAG</p>
          </div>
        </section>
      </section>
    </div>
  );
}

export default Styleguide;
