import React from 'react';

const Button = ({
  label,
  onClick = () => {},
  type = 'primary',
  size = 'normal',
  style = {},
}) => {
  return (
    <div
      className={`cta cta--square cta--${type} cta--${size}`}
      style={style}
      onClick={onClick}>
      <h4>{label}</h4>
    </div>
  );
};

export default Button;
