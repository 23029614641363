import React, { useState, useEffect } from 'react';
import lottie from 'lottie-web';
import json from './scroll_prompt.json';

const ScrollPrompt = () => {
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    let timer;
    window.addEventListener('scroll', (e) => {
      window.clearTimeout(timer);

      timer = setTimeout(() => setOpacity(1), 5000);

      setOpacity(0);
    });

    const scroll = document.getElementsByClassName('scroll')[0];
    lottie.loadAnimation({
      container: scroll,
      animationData: json,
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
  }, []);
  return <div className='scroll' style={{ opacity }}></div>;
};

export default ScrollPrompt;
