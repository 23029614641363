import React, { useState } from 'react';

function Block({ title, icon, color, hash, on }) {
  const [colour, setColour] = useState(color);

  const onClick = (e) => {
    e.preventDefault();
    const el = document.getElementById(hash);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const onMouseEnter = () => setColour(`${color}--dark`);
  const onMouseLeave = () => setColour(color);

  const style = {
    opacity: on ? 1 : 0,
  };

  return (
    <div
      className={`block ${colour}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <img src={icon} alt={title} style={style} />
      <h2 style={style}>{title}</h2>
    </div>
  );
}

export default Block;
