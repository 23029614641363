import React, { useEffect, useState } from 'react';

const MenuItem = ({ hash, label, fadeInBottomClass, style, onClick }) => {
  return (
    <a
      href='/'
      onClick={(e) => {
        e.preventDefault();
        onClick();
        setTimeout(() => {
          const el = document.getElementById(hash);
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        }, 1200);
      }}>
      <h3
        className={`secondary cssanimation ${fadeInBottomClass}`}
        style={style}>
        {label}
      </h3>
    </a>
  );
};

const Menu = ({ on, onClick }) => {
  const [fadeInBottomClass, setFadeInBottomClass] = useState(null);

  useEffect(() => {
    if (on) {
      setFadeInBottomClass('fadeInBottom');
    } else {
      setFadeInBottomClass(null);
    }
  }, [on]);

  const items = [
    {
      hash: 'what-is-amplify',
      label: 'What is Amplify?',
    },
    {
      hash: 'demand-spaces',
      label: 'Demand Spaces',
    },
    {
      hash: 'achieving-a-goal',
      label: 'Achieving a goal',
      style: {
        color: 'var(--color-green-dark)',
        fontSize: '16px',
        fontStyle: 'italic',
      },
    },
    {
      hash: 'indulge-my-passions',
      label: 'Indulge my passions',
      style: {
        color: 'var(--color-red-dark)',
        fontSize: '16px',
        fontStyle: 'italic',
      },
    },
    {
      hash: 'take-me-away',
      label: 'Take me away',
      style: {
        color: 'var(--color-orange-dark)',
        fontSize: '16px',
        fontStyle: 'italic',
      },
    },
    {
      hash: 'feel-good',
      label: 'Feel good',
      style: {
        color: 'var(--color-blue-dark)',
        fontSize: '16px',
        fontStyle: 'italic',
      },
    },
    {
      hash: 'keep-me-company',
      label: 'Keep me company',
      style: {
        color: 'var(--color-pink-dark)',
        fontSize: '16px',
        fontStyle: 'italic',
      },
    },
    {
      hash: 'quick-news-fix',
      label: 'Quick news fix',
      style: {
        color: 'var(--color-yellow-dark)',
        fontSize: '16px',
        fontStyle: 'italic',
      },
    },
    {
      hash: 'clips',
      label: 'Clips',
    },
    {
      hash: 'reports',
      label: 'Resources',
    },
    {
      hash: 'contact',
      label: 'Contacts',
    },
    {
      hash: '',
      label: '',
    },
  ];
  return (
    <div className='container'>
      <nav>
        {items.map((item) => (
          <MenuItem
            {...item}
            key={item.hash}
            fadeInBottomClass={fadeInBottomClass}
            onClick={onClick}
          />
        ))}
      </nav>
    </div>
  );
};

export default Menu;
